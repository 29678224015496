import axios from 'axios';

export default function () {
  function clickUnsubsribe() {
    const form = document.querySelector('#submitUnsubscribeForm');
    if (form === null) return;

    var formData = {
      language: pageJson.languageCode,
      gameCodes: []
    };

    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      formData.email = form.querySelector('#Email').value;
      formData.gameCodes = [];

      form.querySelectorAll('input[type="checkbox"]').forEach(function (el) {
        if (el.checked) {
          formData.gameCodes.push(el.id);
        }
      });

      var response = grecaptcha.getResponse();

      if (response.length > 0) {
        formData.Request = response;
        axios
          .post('/v1/subscriptionapi/unsubscribe', formData)
          .then(function (response) {
            form.querySelector('#alertbox').innerHTML = response.data;
            form.querySelectorAll('.alert').forEach(function (el) {
              setTimeout(() => {
                el.classList.add('d-none');
              }, 20000);
            });
          })
          .catch(function (error) {
            form.querySelector('#alertbox').innerHTML = error.response.data;
          });
      }
    });
  }

  clickUnsubsribe();
}
