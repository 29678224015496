import { calendar } from './calendar.js';

export default function () {
  function h5Hapi() {
    function changeGameCode() {
      const el = document.querySelector('#GameCode');
      el.addEventListener('change', (e) => {
        e.preventDefault();
        const url = new URL(window.location);

        const gc = el.value;
        url.searchParams.set('gc', gc);
        window.location = url;
      });
    }

    function changeDrawDate() {
      const el = document.querySelector('#DrawDate');

      calendar(el, function (dt) {
        const url = new URL(window.location);
        url.searchParams.set('date', dt);
        window.location = url;
      });
    }

    function changeOrganizationCode() {
      const el = document.querySelector('#OrganizationCode');
      el.addEventListener('change', (e) => {
        e.preventDefault();
        const url = new URL(window.location);

        const oc = el.value;
        url.searchParams.set('oc', oc);
        window.location = url;
      });
    }

    changeGameCode();
    changeDrawDate();
    changeOrganizationCode();
  }
  h5Hapi();
}
