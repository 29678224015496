import { calendar } from './calendar.js';
import axios from 'axios';

export default function () {
  function supportHtmlHist() {
    if (window.history && history.pushState) {
      h5Hapi();
    }
    return !!(window.history && history.pushState);
  }

  function h5Hapi() {
    function updateContent(args, append = false) {
      axios
        .get('', { params: args, headers: { 'X-Requested-With': 'XMLHttpRequest' } })
        .then(function (response) {
          const results = document.querySelector('#searchNumbersResults');
          if (append) {
            var e = document.createElement('template');
            e.innerHTML = response.data;
            results.firstElementChild.insertAdjacentHTML(
              'beforeend',
              e.content.firstElementChild.innerHTML
            );
          } else {
            results.innerHTML = response.data;
            clickLoadMore();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    function clearForm() {
      document.querySelector('#searchNumbersResults').innerHTML = '';

      const gc = document.querySelector('#GameCode').value;
      const sd = document.querySelector('#StartDate');
      const ed = document.querySelector('#EndDate');
      sd._flatpickr.setDate(null);
      ed._flatpickr.setDate(null);
      const url = new URL(window.location);
      url.searchParams.set('gc', gc);
      url.searchParams.delete('sd');
      url.searchParams.delete('ed');
      const args = { gc: gc };
      history.pushState(args, '', url);
    }

    function changeGameCode() {
      const el = document.querySelector('#GameCode');
      el.addEventListener('change', (e) => {
        e.preventDefault();
        search();
      });
    }

    function changeDates() {
      const sd = document.querySelector('#StartDate');
      const ed = document.querySelector('#EndDate');

      calendar(sd, function (dt) {
        if (ed.value && ed.value < dt) {
          ed._flatpickr.setDate(dt);
        }
      });

      calendar(ed, function (dt) {
        if (sd.value && sd.value > dt) {
          sd._flatpickr.setDate(dt);
        }
      });
    }

    function clickSearch() {
      const el = document.querySelector('#searchNumbers');
      el.addEventListener('click', (e) => {
        e.preventDefault();
        search();
      });
    }

    function clickClear() {
      const el = document.querySelector('#clearNumbers');
      el.addEventListener('click', (e) => {
        e.preventDefault();
        clearForm();
      });
    }

    function clickLoadMore() {
      const el = document.querySelector('#loadMore');
      el.addEventListener('click', (e) => {
        e.preventDefault();

        const gc = document.querySelector('#GameCode').value;
        const sd = document.querySelector('#StartDate').value;
        const ed = document.querySelector('#EndDate').value;
        const pg = parseInt(el.dataset['val']);
        const max = parseInt(el.dataset['max']);

        var args = { gc: gc };
        if (sd) {
          args.sd = sd;
        }
        if (ed) {
          args.ed = ed;
        }
        args.pg = pg;

        if (pg >= max) {
          el.classList.add('d-none');
        }
        el.dataset['val'] = pg + 1;
        updateContent(args, true);
      });
    }

    function search() {
      const gc = document.querySelector('#GameCode').value;
      const sd = document.querySelector('#StartDate').value;
      const ed = document.querySelector('#EndDate').value;

      const url = new URL(window.location);
      url.searchParams.set('gc', gc);
      var args = { gc: gc };
      if (sd) {
        args.sd = sd;
        url.searchParams.set('sd', sd);
      }
      if (ed) {
        args.ed = ed;
        url.searchParams.set('ed', ed);
      }

      updateContent(args);
      history.pushState(args, '', url);
    }

    window.onpopstate = (e) => {
      updateContent(event.state);
    };
    changeGameCode();
    changeDates();
    clickSearch();
    clickClear();
    clickLoadMore();
  }

  supportHtmlHist();
}
