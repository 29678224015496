export default function () {
  function h5Hapi() {
    function changeGameCode() {
      const el = document.querySelector('#GameCode');
      el.addEventListener('change', (e) => {
        e.preventDefault();
        const url = new URL(window.location);
        url.searchParams.delete('pg');

        const gc = el.value;
        url.searchParams.set('gc', gc);
        window.location = url;
      });
    }

    function changeJackpot() {
      const el = document.querySelector('#IsJackpot');
      el.addEventListener('change', (e) => {
        e.preventDefault();
        const url = new URL(window.location);
        url.searchParams.delete('pg');

        const jp = el.checked;
        if (jp === false) {
          url.searchParams.delete('jp');
        } else {
          url.searchParams.set('jp', jp);
        }
        window.location = url;
      });
    }

    changeGameCode();
    changeJackpot();
  }

  h5Hapi();
}
